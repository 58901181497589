.Instruction {
    font-size: 3em;
}

.Copyright {
    margin-top: 2px;
}

.MuiStepLabel-labelContainer {
    margin-top: 3px !important;
}

.MuiPaper-elevation4 {
    box-shadow: none !important;
}